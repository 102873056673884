import type { ReactNode } from 'react';

import type { RemoteImageProps } from '../../primitives';
import { Wrapper, Image, Overlay } from './CoverSection.parts';

export type CoverSectionProps = {
	backgroundImageUrl?: RemoteImageProps['url'];
	children?: ReactNode;
	overlayColor?: string;
};

// TODO: Make this handle loading state?
export function CoverSection(props: CoverSectionProps & { className?: string }) {
	return (
		<Wrapper className={props.className}>
			{props.backgroundImageUrl && <Image url={props.backgroundImageUrl} />}
			<Overlay overlayColor={props.overlayColor}>{props.children}</Overlay>
		</Wrapper>
	);
}
